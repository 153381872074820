import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const KetentuanCard = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/ketentuan');
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="div" sx={{ textAlign: 'left' }}>
            Ketentuan Lab AP-02
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            Tolong dipatuhi ya diksss...
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            size="small" 
            onClick={handleNavigate}
          >
            Lihat ketentuan
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default KetentuanCard;
