import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AsistenHome from './pages/asistenHome';
import AsistenNavbar from './pages/components/asistenNavbar';
import AsistenPersiapan from './pages/asistenPersiapan';
import AsistenKetentuan from './pages/asistenKetentuan';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
          <AsistenNavbar />
        <Routes>
          <Route path="/" element={<AsistenHome />} />
          <Route path="/persiapan" element={<AsistenPersiapan />} />
          <Route path="/ketentuan" element={<AsistenKetentuan />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
