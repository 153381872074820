import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const card = (
  <React.Fragment>
    <CardContent>
      <Typography variant="h5" component="div" sx={{ textAlign: 'left' }}>
        Repository Github AP-02
      </Typography>
      <Typography variant="body2" sx={{ textAlign: 'left' }}>
        Kumpul tugas disini ya diksss...
      </Typography>
    </CardContent>
    <CardActions>
      <Button 
        size="small" 
        href="https://github.com/kelvinleonardos/LAB-AP-02-2024" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Lihat repo
      </Button>
    </CardActions>
  </React.Fragment>
);

export default function RepoCard() {
  return (
    <Box>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
