import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid2, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

export default function AsistenTutorGit() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Download & Install Git
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/git_install/1.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Pergi ke website <a href="https://git-scm.com/" target="_blank" rel="noreferrer">https://git-scm.com/</a></li>
                                <li>Klik download for Windows</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/git_install/2.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Klik "64-bit Git for Windows Setup."</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/git_install/3.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Tunggu hingga download selesai</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/git_install/4.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Buka file yang sudah di download dan lakukan instalasi seperti biasa (next next saja)</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Buat Akun GitHub
        </AccordionSummary>
        <AccordionDetails>
        <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_github_account/1.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Pergi ke website <a href="https://github.com/" target="_blank" rel="noreferrer">https://github.com/</a></li>
                                <li>Klik sign up</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_github_account/2.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Isi seluruh data yang dibutuhkan</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_github_account/3.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Berikut tampilan utama saat berhasil sign up</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Buat Personal Access Token
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/1.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Pergi ke website <a href="https://github.com/" target="_blank" rel="noreferrer">https://github.com//</a></li>
                                <li>Klik icon profile di kanan atas</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/2.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Klik settings</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/3.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Scroll bagian daftar menu di sebelah kiri, dan klik developer settings</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/4.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Di daftar menu sebelah kiri, klik personal access tokens</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/5.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Klik tokens (classic)</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/6.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Di tampilan utama, klik generate new token di bagian atas</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/7.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Klik generate new token (classic)</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/8.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Verifikasi akun anda terlebih dahulu bila diminta</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/9.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Berikut tampilan bila sudah memverifikasi akun</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/10.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Pada bagian note, isi bebas, namun berikan penanda bahwa token ini digunakan untuk repository github</li>
                                <li>Pada bagian expiration, anda bebas memasukkan durasinya, nantinya, sebelum durasi tokennya habis, anda akan diingatkan melalui email oleh github untuk refresh token, agar tetap dapat digunakan</li>
                                <li>Pada bagian select scopes, centang hanya pada bagian repo</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/11.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Scroll ke bagian paling bawah, dan klik generate token</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/create_access_token/12.png" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Copy token dan simpan di tempat yang aman</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          Download & Install Python
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/python_install/1.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Pergi ke website <a href="https://www.python.org/downloads/" target="_blank" rel="noreferrer">https://www.python.org/downloads/</a></li>
                                <li>Klik download python 3......</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/python_install/2.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Tunggu hingga download selesai</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/python_install/3.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Buka file exe yang sudah di download</li>
                                <li>Centang "use admin ..."</li>
                                <li>Centang "Add Python 3..... to PATH"</li>
                                <li>Klik install now</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
            <Stack spacing={2} style={{ paddingBottom: '20px' }}>
                <Item>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, md: 5 }}>
                            <Item>
                                <img src="./screenshot/python_install/4.jpeg" alt="git" style={{ width: '100%', height: 'auto' }} />
                            </Item>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                <li>Close window setelah instalasi selesai</li>
                            </ul>
                        </Grid2>
                    </Grid2>
                </Item>
            </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}