import { Box, Divider, Paper, Stack, Toolbar, styled } from "@mui/material";
import AsistenTutorGit from "./components/asistenTutorGit";
import RepoCard from "./components/repoCard";
import KetentuanCard from "./components/ketentuanCard";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

export default function AsistenPersiapan() {
    return (
        <div>
            <Toolbar />
            <Box component="section" sx={{ p: 2 }}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        overflowX: 'auto', 
                        marginBottom: 2,
                        '&::-webkit-scrollbar': { display: 'none' } 
                    }}
                >
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        sx={{ minWidth: 'max-content' }} // Agar Stack tidak kolaps saat content lebih lebar
                    >
                        <Item><RepoCard /></Item>
                        <Item><KetentuanCard /></Item>
                    </Stack>
                </Box>
                <AsistenTutorGit />
                <h6>Tutorial dipersembahkan oleh mas beni ganteng</h6>
            </Box>
        </div>
    );
}
