import { Box, Toolbar } from "@mui/material";

export default function AsistenHome() {
  return (
    <div >
      <Toolbar />
      <Box component="section" sx={{ p: 2 }}>
          <h1>Welcome...</h1>
      </Box>
  </div>
  );
}