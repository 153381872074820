import { Box, Toolbar } from "@mui/material";

export default function AsistenKetentuan() {
  return (
    <div>
      <Toolbar />
      <Box 
        component="section" 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' // Atur ketinggian Box untuk menengah vertikal
        }}
      >
        <embed 
          src="ketentuan.pdf" 
          width="90%" 
          height="600px" 
          type="application/pdf" 
        />
      </Box>
    </div>
  );
}
